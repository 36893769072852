.roadmap-card-container > .ant-card-body {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: flex-start;
  position: relative !important;
}

.vertical-timeline-element-icon svg {
  margin-top: 0px !important;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.roadmap-notes-form {
  max-width: 500px;
}

.roadmap-no-data {
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fileactive-card-container > .ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative !important;
}
