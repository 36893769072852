/*   color variables */
$clr-primary: #ff7043;
$clr-primary-hover: #29e6a7;
$clr-primary-dark: #006b96;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;

/*   border radius */
$radius: 0.2rem;

$animation: cubic-bezier(0.785, 0.18, 0.415, 1.65);

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.wrapper-container {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 30px;
  right: -22%;
  display: grid;
  justify-content: center;
  align-items: center;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  button {
    font-family: inherit;
    outline: none;
    border: none;
    background-color: white;
    padding: 1rem;
    width: 4rem;
    height: 4rem;
    box-shadow: 0px 0px 10px $clr-gray400;
    border-radius: 50%;
    cursor: pointer;

    svg {
      width: 2rem;
      height: 2rem;
      fill: $clr-gray500;
      transition: 500ms transform $animation;
    }

    &:hover {
      transform: scale(1.1);
    }
    &:focus {
      box-shadow: 0 0 0 3px $clr-gray500;
    }
  }

  .add {
    z-index: 99;
    transition: 500ms transform $animation;
  }

  .event,
  .orientation,
  .note {
    position: relative;
    bottom: -6rem;
    opacity: 0;
    transform: rotatez(-45deg);
    transition: 500ms all $animation;
    pointer-events: none;
  }

  &-open .event,
  &-open .orientation,
  &-open .note {
    opacity: 1;
    bottom: 0;
    transform: rotatez(0deg);
    pointer-events: auto;

    &::after {
      content: attr(title);
      position: absolute;
      left: 5rem;
      top: 50%;
      transform: translatey(-50%);
      background-color: $clr-gray500;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: $radius;
      pointer-events: none;
      display: none;
    }
  }

  &-open .event:hover::after,
  &-open .orientation:hover::after,
  &-open .note:hover::after {
    display: block;
  }

  &-open .add svg {
    transform: rotatez(135deg);
  }
}
