.forgot-pwd-form {
  max-width: 500px;
}

.forgot-pwd-form-button {
  width: 100%;
}

.forgot-pwd-form-button-text {
  color: #17ac8b;
}
