.vertical-timeline-element-content {
  -webkit-box-shadow: 0 0px 0 #ddd !important;
  box-shadow: 0px 0px 5px #767b91;
}

.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 2px;
  background: #767b91;
}
