.action-render-delete-button-container {
  margin-right: 6px;
}

.separate-filter-div {
  width: 33% !important;
}

.create-users-container {
  width: 34% !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.user-table-button-container {
  justify-content: flex-end !important;
  margin-right: 18px !important;
}
