.header-head {
  height: 48 !important;
  padding: 0 !important;
  z-index: 2 !important;
  margin: "16px 0" !important;
}

.header-title {
  font-size: 25 !important;
  height: "100%" !important;
}

.header-contant-children {
  height: "100%" !important;
  position: "relative" !important;
}

.header-container {
  background: white;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.header-end-container {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.header-start-container {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.header-logo-image {
  width: 250px;
  height: 80px;
}
