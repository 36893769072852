.ant-collapse-header {
  display: flex;
}

.dropdown_wrapper {
  justify-content: start;
  align-items: start;
  width: auto;
  min-width: 150px;
  margin: 10px;
}

.filters_container {
  display: flex;
  flex-direction: row;
}

.ant-space-vertical {
  width: 100%;
}

.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.button-reset {
  font-family: inherit;
  outline: none;
  border: none;
  background-color: white;
  color: #17ac8b;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  z-index: 20;
  right: 18px;
  top: 12px;
}
