.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
  pointer-events: none;
  z-index: 999;
  opacity: 0;
}

.loader-wrapper.loading {
  pointer-events: auto;
  opacity: 1;
}

.loader-wrapper.loaded {
  -webkit-animation: 0.5s fade-delayed 0.2s ease-in forwards;
  animation: 0.5s fade-delayed 0.2s ease-in forwards;
}

.loader-wrapper .folding-cube {
  width: 60px;
  height: 60px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader-wrapper .folding-cube .cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.loader-wrapper .folding-cube .cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-animation: foldCubeAngle 1.4s infinite linear both;
  animation: foldCubeAngle 1.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.loader-wrapper .folding-cube .cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.loader-wrapper .folding-cube .cube2:before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loader-wrapper .folding-cube .cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.loader-wrapper .folding-cube .cube3:before {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.loader-wrapper .folding-cube .cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.loader-wrapper .folding-cube .cube4:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@-webkit-keyframes fade-delayed {
  100% {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes fade-delayed {
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
